import { TableColumnsType } from "antd";
import { CategoryType } from "../../../types/category.types";
import _ from "lodash";

export const columns: TableColumnsType<CategoryType> = [
  {
    title: "Id",
    dataIndex: "_id",
    width: "5%",
    ellipsis: true,
  },
  {
    title: "Category Img",
    dataIndex: "category_img",
    width: "9%",
    render(value, record, index) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={record?.category_img}
            alt={record.name}
            style={{ height: "40px", width: "40px" }}
          />
        </div>
      );
    },
  },
  {
    title: "Brand Img",
    dataIndex: "brand.brand_img",
    width: "9%",
    render(value, record, index) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={record?.brand?.brand_img}
            alt={record.name}
            style={{ height: "40px", width: "40px" }}
          />
        </div>
      );
    },
  },
  {
    title: "Category Name",
    dataIndex: "name",
    width: "15%",
    sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
    render(value, record, index) {
      return <strong>{_.capitalize(record?.name)}</strong>;
    },
  },
  {
    title: "Brand",
    dataIndex: "brand",
    width: "10%",
    ellipsis: true,
    render(value, record, index) {
      return <strong>{_.capitalize(record?.brand?.name)}</strong>;
    },
    sorter: (a: any, b: any) => a?.brand.name.localeCompare(b?.brand.name),
  },
  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    render(value, record, index) {
      return (
        <div>{new Date(_.toString(record?.createdAt)).toDateString()}</div>
      );
    },
  },
  {
    title: "UpdatedAt",
    dataIndex: "updatedAt",
    render(value, record, index) {
      return (
        <div>{new Date(_.toString(record?.updatedAt)).toDateString()}</div>
      );
    },
  },
  {
    title: "CreatedBy",
    dataIndex: "createdBy",
    onFilter: (value, record) =>
      _.toString(record.createdBy).indexOf(value as string) === 0,
    sorter: (a, b) =>
      _.toString(a.createdBy).length - _.toString(b.createdBy).length,
    sortDirections: ["descend"],
  },
  {
    title: "UpdatedBy",
    dataIndex: "updatedBy",
    onFilter: (value, record) =>
      _.toString(record.updatedBy).indexOf(value as string) === 0,
    sorter: (a, b) =>
      _.toString(a.updatedBy).length - _.toString(b.updatedBy).length,
    sortDirections: ["descend"],
    ellipsis: true,
  },
  {
    title: "Action",
    key: "action",
    // fixed: true,
    width: "10%",
  },
];

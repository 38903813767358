import { Typography, Card, Col, Row } from "antd";
import { ReactElement, useEffect, FC } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getAllCategory } from "../../redux/actions/category_services";
import { brandsData, categoriesData, usersData } from "./selectror";
import { getAllBrands } from "../../redux/actions/brand_services";
import { getusers } from "../../redux/actions/user_service";
import { count } from "./utils/constants";
import Echart from "../../ant_components/Charts/EChart";
import LineChart from "../../ant_components/Charts/LineChart";

interface IProps {}
const { Title } = Typography;

const Dashboard: FC<IProps> = (): ReactElement => {
  const appDispatch = useAppDispatch();
  const allCategories = useAppSelector(categoriesData);
  const allUsers = useAppSelector(usersData);
  const allBrands = useAppSelector(brandsData);

  useEffect(() => {
    appDispatch(getAllCategory());
    appDispatch(getAllBrands());
    appDispatch(getusers());
  }, [appDispatch]);

  console.log({ allBrands, allCategories, allUsers }, "sssssssss");

  const [totalBrands, totalCategories, totalUsers] = [
    allBrands?.length,
    allCategories?.length,
    allUsers?.length,
  ];

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count(totalBrands, totalCategories, totalUsers).map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.today}</span>
                    <Title level={3}>
                      {c.title} <small className={c.bnb}>{c.persent}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">{c.icon}</div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox">
            <Echart />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox">
            <LineChart />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { accessToken } from "../pages/Auth/selectros";
import Layout from "../ant_components/Layout";

export type WithChildrenProps<T = undefined> = T extends undefined
  ? {
      children?: ReactNode;
    }
  : T & {
      children?: ReactNode;
    };
const RequireAuth: FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector(accessToken);
  return token ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default RequireAuth;

import _ from "lodash";
import { TableColumnsType } from "antd";
import { UserModel } from "../../../types/user.types";

export const columns: TableColumnsType<UserModel> = [
  {
    title: "Id",
    dataIndex: "_id",
    width: "2%",
    ellipsis: true,
  },
  {
    title: "username",
    dataIndex: "name",
    width: "5%",
    sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
    render(value, record, index) {
      return <strong>{_.capitalize(record?.name)}</strong>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "10%",
    render(value, record, index) {
      return <strong>{_.capitalize(record?.email)}</strong>;
    },
  },
  {
    title: "Contact No.",
    dataIndex: "phoneNumber",
    width: "15%",
    render(value, record, index) {
      return <strong>{_.capitalize(record?.phoneNumber)}</strong>;
    },
  },
  {
    title: "User Type",
    dataIndex: "typeOfuser",
    width: "10%",
    ellipsis: true,
    render(value, record, index) {
      return <strong>{_.capitalize(record?.typeOfuser)}</strong>;
    },
    sorter: (a: any, b: any) => a?.typeOfuser.localeCompare(b?.typeOfuser),
  },
  {
    title: "Action",
    key: "action",
    // fixed: true,
    width: "10%",
  },
];

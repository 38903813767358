import _ from "lodash";
import { FC, useEffect } from "react";
import {
  Button,
  Flex,
  Form,
  Grid,
  Input,
  Select,
  Spin,
  theme,
  Typography,
} from "antd";
import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getAllTypeOfUserSelector, userData } from "./selectros";
import { getuserTypes } from "../../redux/actions/user_service";
import { ISignupFormData } from "./constants/auth_constant";
import { signUpPayload } from "../../types/user.types";
import { signup } from "../../redux/actions/auth_servces";
import { persistUser } from "../../config/storage";
import Logo from "../../assets/imges/Transparent.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;
const { Option } = Select;

const SignUp: FC<any> = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allTypesOfusers = useAppSelector(getAllTypeOfUserSelector);
  const { loading } = useAppSelector((state: RootState) => state.auth_reducer);
  const user = useAppSelector(userData);

  if (user && user?.name !== "") {
    persistUser(user);
    form.resetFields();
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  }

  useEffect(() => {
    dispatch(getuserTypes());
  }, [dispatch]);

  const onFinish = (values: signUpPayload) => {
    dispatch(signup(values));
  };

  const handleChange = (e: any) => {
    form.setFieldsValue({ typeOfuser: e });
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <section style={styles.section}>
      {loading ? (
        <Flex
          align="center"
          gap="middle"
          justify="center"
          style={{ height: "100vh", display: "flex", justifyContent: "center",width:'100%' }}
        >
          <Spin size="large" />
        </Flex>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <img
              src={Logo}
              alt="logo"
              style={{ height: "170px", width: "100%" }}
            />
            <Title style={styles.title}>Sign Up</Title>
            <Text style={styles.text}>
              Welcome back to Listdekhoo Please enter your details below to sign
              up.
            </Text>
          </div>
          <Form
            name="normal_login"
            initialValues={ISignupFormData}
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Username"
                onChange={(e) => _.capitalize(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phoneNumber!",
                  min: 10,
                },
              ]}
            >
              <Input prefix={<PhoneOutlined />} placeholder="PhoneNumber" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="typeOfuser"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Select
                placeholder="Select type of user"
                onChange={(e) => handleChange(e)}
                allowClear
              >
                {_.map(allTypesOfusers, (e) => (
                  <Option value={e?.typeOfUser} key={e?._id}>
                    {e?.typeOfUser}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" htmlType="submit">
                Signup
              </Button>
              <div>
                <Text> have an account?</Text>
                <Link onClick={() => navigate("/auth/login")}>Sign In</Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </section>
  );
};

export default SignUp;

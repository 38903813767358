import { FC } from "react";
import { Button, Flex, Form, Grid, Input, Spin, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { forgot_password } from "../../redux/actions/auth_servces";
import Logo from "../../assets/imges/Transparent.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const ForgotPassword: FC<any> = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state: RootState) => state.auth_reducer);

  const onFinish = (values: any) => {
    dispatch(forgot_password(values?.email));
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <section style={styles.section}>
      {loading ? (
        <Flex
          align="center"
          gap="middle"
          justify="center"
          style={{ height: "100vh", display: "flex", justifyContent: "center",width:'100%' }}
        >
          <Spin size="large" />
        </Flex>
      ) : (
      <div style={styles.container}>
        <div style={styles.header}>
          <img
            src={Logo}
            alt="logo"
            style={{ height: "170px", width: "100%" }}
          />
          <Title style={styles.title}>Forgot password?</Title>
          <Text style={styles.text}>
            Enter your email address to receive a password reset link.
          </Text>
        </div>
        <Form
          form={form}
          name="normal_login"
          initialValues={{
            remember: true,
            email: "",
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block={true} type="primary" htmlType="submit">
              Reset Password
            </Button>
            <div>
              <Text>Remember your password?</Text>
              <Link onClick={() => navigate("/auth/login")}>Login</Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    )}
    </section>
  );
};

export default ForgotPassword;

import _ from "lodash";
import { ReactElement, useEffect, FC, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row, Select } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getAllBrands } from "../../redux/actions/brand_services";
import {
  deleteCategory,
  getAllCategory,
  getCategoryByBrandName,
} from "../../redux/actions/category_services";
import { sidenavEnums } from "../../context/types";
import { useMaterialUIController } from "../../context/mui.context";
import { columns } from "./utils/columns";
import { getAllBrandSelector } from "../Brand/selectors";
import { BrandType } from "../../types/brand.types";
import { Allcategories } from "./selectros";
import AddEditCategory from "./components/addEditCategory";
import AntTable from "../../ant_components/Table";
import ColumnsBuilder from "../../ant_components/Table/lib/columnBuilder";
import styles from "./style.module.scss";

const { Option } = Select;

const Category: FC<any> = (): ReactElement => {
  const [brandDetail, setBrandDetail] = useState<any>();
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const appDispatch = useAppDispatch();
  const brands = useAppSelector(getAllBrandSelector);
  const categories = useAppSelector(Allcategories);
  const {
    muiState: { openDrawer, drawerTitle },
    dispatch,
  } = useMaterialUIController();
  const { loading, refresh } = useSelector(
    (state: RootState) => state.category_reducers
  );

  useEffect(() => {
    appDispatch(getAllCategory());
    appDispatch(getAllBrands());
  }, [refresh, appDispatch]);

  useEffect(()=>{

  },[])

  const columnsBuilder = new ColumnsBuilder()
    .basedOn(columns)
    .merge("action", {
      align: "center",
      render: (text, record) => (
        <Row
          gutter={16}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              dispatch({
                type: sidenavEnums?.DRAWER_TITLE,
                payload: "Edit Category",
              });
              dispatch({
                type: sidenavEnums?.OPEN_DRAWER,
                payload: true,
              });
              setSelectedCategory(record);
            }}
          >
            <EditOutlined />
          </Button>
          <Button onClick={() => appDispatch(deleteCategory(record?._id))}>
            <DeleteOutlined />
          </Button>
        </Row>
      ),
    })
    .build();

  const handleChange = (e: any) => {
    const getBrandData = _.find(
      brands,
      (brand: BrandType) => _.toLower(brand?.name) === _.toLower(e)
    );
    setBrandDetail(getBrandData);
    appDispatch(getCategoryByBrandName(e));
    setSelectedBrand(e);
  };

  return (
    <Row className={styles.container}>
      <Col span={24} className={styles.add_brand}>
        <Row justify="space-between" className={styles.header}>
          <Col span={12}>
            <Select
              placeholder="Select brand"
              onChange={(e) => handleChange(e)}
              allowClear
              style={{ width: "150px", borderRadius: "10px" }}
            >
              {_.map(brands, (e) => (
                <Option value={e?.name} key={e?._id}>
                  {e?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12} className={styles.addBtn}>
            <Button
              children="Add Category"
              onClick={() => {
                dispatch({
                  type: sidenavEnums?.OPEN_DRAWER,
                  payload: true,
                });
                dispatch({
                  type: sidenavEnums?.DRAWER_TITLE,
                  payload: "Add Category",
                });
              }}
              disabled={selectedBrand === ""}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AntTable
          columns={columnsBuilder}
          data={categories}
          loading={loading}
        />
      </Col>
      {openDrawer && drawerTitle !== "" && (
        <AddEditCategory
          brandDetails={brandDetail}
          selectedCategory={selectedCategory}
        />
      )}
    </Row>
  );
};

export default Category;

import { createSlice } from "@reduxjs/toolkit";
import { BrandType } from "../../types/brand.types";
import {
  createBrand,
  deleteBrand,
  getAllBrands,
  updateBrand,
} from "../actions/brand_services";

interface IBrand {
  brand_data: BrandType[];
  refresh: boolean;
  success_message: string;
  error_message: string;
  loading: boolean;
}

const Istate: IBrand = {
  brand_data: [],
  loading: false,
  error_message: "",
  success_message: "",
  refresh: false,
};

const brandReducer = createSlice({
  name: "brand",
  initialState: Istate,
  reducers: {
    refreshBrands(state, action) {
      state.refresh = action.payload;
    },
    clearMessage(state, action) {
      state.success_message = "";
      state.error_message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBrands.pending, (state: IBrand) => {
        state.loading = true;
      })
      .addCase(getAllBrands.fulfilled, (state: IBrand, action) => {
        state.loading = false;
        state.refresh = false;
        state.brand_data = action.payload;
      })
      .addCase(getAllBrands.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
      })
      .addCase(createBrand.pending, (state: IBrand) => {
        state.loading = true;
      })
      .addCase(createBrand.fulfilled, (state: IBrand, action) => {
        state.loading = false;
        state.success_message = "Brand Created SuccessFul";
        state.refresh = true;
      })
      .addCase(createBrand.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
        state.refresh = true;
      })
      .addCase(updateBrand.pending, (state: IBrand) => {
        state.loading = true;
        state.refresh = false;
      })
      .addCase(updateBrand.fulfilled, (state: IBrand, action) => {
        state.loading = false;
        state.refresh = true;
        state.success_message = "brand updated successfully";
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
        state.refresh = true;
      })
      .addCase(deleteBrand.pending, (state: IBrand) => {
        state.loading = true;
        state.refresh = false;
      })
      .addCase(deleteBrand.fulfilled, (state: IBrand, action) => {
        state.loading = false;
        state.refresh = true;
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
        state.refresh = true;
      });
  },
});

export const { clearMessage, refreshBrands } = brandReducer.actions;

export default brandReducer.reducer;

export const DEFAULT_MAX_FILE_SIZE_IN_MB = 5;
export const DEFAULT_MAX_FILE_SIZE_IN_MB_UP = 150;
export const DEFAULT_MAX_FILE_SIZE_IN_MB_EMP = 20;
export const DEFAULT_UPLOAD_WIDTH = 250;
export const SUPPORTED_FILE_TYPE = ["image/jpeg", "image/png"];
export const PDF_SUPPORTED_FILE_TYPE = ["application/pdf"];

export const PNG_OR_JPG_IMAGE_FORMAT = "Please use PNG or JPG image format.";
export const MAX_SIZE_IN_MB = "The maximum image size allowed is {0} MB.";
export const MAX_SIZE_IN_MB_UP = "The maximum image size allowed is {0} MB.";
export const MAX_SIZE_IN_MB_EMP = "The maximum image size allowed is 20 MB.";
export const FILE_UPLOAD_DONE = "done";
export const FILE_UPLOAD_ERROR = "error";
export const FILE_UPLOADING = "uploading";
export const PDF_FORMAT = "Please use PDF format.";

export const getBase64 = (file: Blob, callback: (result: any) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
};
import { FC, ReactElement } from "react";
import { Table, TableColumnsType } from "antd";
import { BrandType } from "../../types/brand.types";

interface IProps {
  columns: TableColumnsType<BrandType>;
  data: any;
  handleChange?: () => void;
  loading?: boolean;
}

const AntTable: FC<IProps> = ({
  columns,
  data,
  handleChange,
  loading,
}): ReactElement => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={handleChange}
      pagination={{ pageSize: 50 }}
      rowKey={(record) => record._id}
      scroll={{ y: 388 }}
      bordered
      loading={loading}
    />
  );
};

export default AntTable;

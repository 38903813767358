import { combineReducers } from "@reduxjs/toolkit";
import brand_reducer from "./brand_reducer";
import category_reducers from "./category_reducers";
import auth_reducer from "./auth_reducer";
import users_reducer from "./user_reducer";

const reducer = combineReducers({
  brand_reducer,
  category_reducers,
  auth_reducer,
  users_reducer,
});

export default reducer;

import { signUpPayload, loginPayload } from "../../../types/user.types";

export const ISignupFormData: signUpPayload = {
  email: "",
  password: "",
  name: "",
  phoneNumber: "",
  typeOfuser: "",
};

export const ILoginFormData: loginPayload = {
  email: "",
  password: "",
};

import { FC, ReactElement, ReactNode } from "react";
import { Layout, Menu, MenuProps, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "../../context/mui.context";
import { router } from "../../routes";
import styles from "./style.module.scss";

interface IProps {
  children?: ReactNode;
}

const { Sider } = Layout;
const items2: MenuProps["items"] = router;

const AntSider: FC<IProps> = (): ReactElement => {
  const navigate = useNavigate();
  const {
    muiState: { miniSidenav },
  } = useMaterialUIController();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={miniSidenav}
      width={200}
      style={{ background: colorBgContainer }}
      className={styles.container}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={["/dashboard"]}
        defaultOpenKeys={["/dashboard"]}
        style={{ height: "100%", borderRight: 0 }}
        items={items2}
        onClick={(e) => navigate(e?.key)}
      />
    </Sider>
  );
};

export default AntSider;

import { FC, ReactElement, ReactNode } from "react";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Layout, Avatar, Row, Col, Dropdown, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "../../context/mui.context";
import { sidenavEnums } from "../../context/types";
import { deleteToken, deleteUser } from "../../config/storage";
import { clearToken } from "../../redux/reducers/auth_reducer";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userInfo } from "../../pages/Auth/selectros";
import Logo from "../../assets/imges/Transparent.png";
import styles from "./style.module.scss";

interface IProps {
  children?: ReactNode;
}

const { Header } = Layout;

const AntHeader: FC<IProps> = (): ReactElement => {
  const {
    dispatch,
    muiState: { miniSidenav, openFullScreen },
  } = useMaterialUIController();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const userDetails = useAppSelector(userInfo);

  const items: MenuProps["items"] = [
    {
      key: "logout",
      label: <li>LogOut</li>,
      icon: <MailOutlined />,
      className: styles.list,
      danger: true,
      style: {
        color: "#000",
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      deleteUser();
      deleteToken();
      appDispatch(clearToken());
      navigate("/auth/login");
    }
  };

  return (
    <Header>
      <Row className={styles.container}>
        <Col className={styles.left}>
          <div className={styles.logo}>
            <img src={Logo} alt="logo" />
          </div>
          <Button
            type="text"
            icon={
              miniSidenav ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "20px", color: "green" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "20px", color: "green" }}
                />
              )
            }
            onClick={() =>
              dispatch({
                type: sidenavEnums.MINI_SIDENAV,
                payload: !miniSidenav,
              })
            }
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Col>
        <Col className={styles.right}>
          {openFullScreen ? (
            <FullscreenOutlined
              onClick={() => {
                document.body.requestFullscreen();
                dispatch({
                  type: sidenavEnums?.FULLSCREEN,
                  payload: false,
                });
              }}
              style={{ fontSize: "30px", color: "green" }}
            />
          ) : (
            <FullscreenExitOutlined
              onClick={() => {
                if (document.fullscreenElement) {
                  document
                    .exitFullscreen()
                    .then(() =>
                      console.log("Document Exited from Full screen mode")
                    )
                    .catch((err) => console.error(err));
                }
                dispatch({
                  type: sidenavEnums?.FULLSCREEN,
                  payload: true,
                });
              }}
              style={{ fontSize: "30px", color: "green" }}
            />
          )}
          <Dropdown menu={{ items, onClick }} placement="bottomCenter">
            <Avatar
              className={styles.avatar}
              style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
            >
              {userDetails?.name}
            </Avatar>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default AntHeader;

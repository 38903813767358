import _ from "lodash";
import { Route } from "react-router-dom";
import { routes, routeType } from "../types/router.types";
import {
  ApartmentOutlined,
  DribbbleOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  BrandPage,
  CategoryPage,
  DashboardPage,
  UserPage,
} from "../pages";

export const router: routeType = [
  {
    label: "Dashboard",
    icon: <HomeOutlined />,
    key: "/dashboard",
    component: <DashboardPage />,
  },
  {
    label: "Brand",
    icon: <DribbbleOutlined />,
    key: "/brands",
    component: <BrandPage />,
  },
  {
    label: "Category",
    icon: <ApartmentOutlined />,
    key: "/categories",
    component: <CategoryPage />,
  },
  {
    label: "User",
    icon: <UsergroupAddOutlined />,
    key: "/users",
    component: <UserPage />,
  },
];

export const getRoutes = (allRoutes: routeType): any => {
  return _.map(allRoutes, (route: routes) => {
    if (route.children) {
      return getRoutes(route.children as any);
    }
    if (route.key) {
      return (
        <Route path={route.key} element={route.component} key={route.key} />
      );
    }
  });
};

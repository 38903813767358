import { FC } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Form,
  Grid,
  Input,
  Spin,
  theme,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { ILoginFormData } from "./constants/auth_constant";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { login } from "../../redux/actions/auth_servces";
import { loginPayload } from "../../types/user.types";
import { accessToken, loginData } from "./selectros";
import { persistToken } from "../../config/storage";
import { getuser } from "../../redux/actions/user_service";
import Logo from "../../assets/imges/Transparent.png";
import React from "react";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const Login: FC<any> = () => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginRes = useAppSelector(loginData);
  const userToken = useAppSelector(accessToken);
  const { loading } = useAppSelector((state: RootState) => state.auth_reducer);

  const onFinish = (values: loginPayload) => {
    dispatch(login(values));
  };

  if (userToken !== "") {
    persistToken(userToken);
    if (loginRes && loginRes?.userId !== "") {
      dispatch(getuser(loginRes?.userId));
      navigate("/");
    }
  }

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <section style={styles.section}>
      {loading ? (
        <Flex
          align="center"
          gap="middle"
          justify="center"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Spin size="large" />
        </Flex>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <img
              src={Logo}
              alt="logo"
              style={{ height: "170px", width: "100%" }}
            />
            <Title style={styles.title}>Sign in</Title>
            <Text style={styles.text}>
              Welcome back to Listdekhoo Please enter your details below to sign
              in.
            </Text>
          </div>
          <Form
            name="normal_login"
            initialValues={ILoginFormData}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Link onClick={() => navigate("/auth/forgot-password")}>
                Forgot password?
              </Link>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" htmlType="submit">
                Log in
              </Button>
              <div>
                <Text>Don't have an account?</Text>
                <Link onClick={() => navigate("/auth/sign-up")}>
                  Sign up now
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </section>
  );
};

export default Login;

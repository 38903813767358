import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import axiosInstance, { brand_ext } from "../../config/axios.config";
import { BrandType, createBrandPayload } from "../../types/brand.types";

export const getAllBrands = createAsyncThunk<BrandType[]>(
  "get_all_brands",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `${brand_ext}/getAll`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const createBrand = createAsyncThunk(
  "createBrand",
  async (data: createBrandPayload, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${brand_ext}/create`,
        data
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateBrand = createAsyncThunk(
  "updateBrand",
  async (data: any, thunkAPI) => {
    const id = data?._id;
    const payload = _.omit(data, "_id");
    try {
      const response: AxiosResponse = await axiosInstance.put(
        `${brand_ext}/update/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "deleteBrand",
  async (id: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${brand_ext}/delete/${id}`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

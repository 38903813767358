import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import axiosInstance, { user_ext } from "../../config/axios.config";

export const getusers = createAsyncThunk("getAllUsers", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await axiosInstance.get(
      `${user_ext}/getAll`
    );
    return response.data;
  } catch (error) {
    const err: AxiosError = error as AxiosError;
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${user_ext}/delete/${id}`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getuser = createAsyncThunk(
  "getUser",
  async (userId: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `${user_ext}/${userId}`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getuserTypes = createAsyncThunk(
  "getUserType",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `${user_ext}/getAllUserTypes`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { CategoryType } from "../../types/category.types";
import {
  createCategory,
  deleteCategory,
  getAllCategory,
  getCategoryByBrandName,
  updateCategory,
} from "../actions/category_services";

interface ICategory {
  category_data: CategoryType[];
  success_message: string;
  error_message: string;
  loading: boolean;
  refresh: boolean;
}

const Istate: ICategory = {
  category_data: [],
  loading: false,
  refresh: false,
  error_message: "",
  success_message: "",
};

const categoryReducer = createSlice({
  name: "category",
  initialState: Istate,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategory.pending, (state: ICategory) => {
        state.loading = true;
      })
      .addCase(getAllCategory.fulfilled, (state: ICategory, action) => {
        state.loading = false;
        state.refresh = false;
        state.category_data = action.payload;
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
        state.refresh = false;
      })
      .addCase(getCategoryByBrandName.pending, (state: ICategory) => {
        state.loading = true;
      })
      .addCase(getCategoryByBrandName.fulfilled, (state: ICategory, action) => {
        state.loading = false;
        state.category_data = action.payload;
      })
      .addCase(getCategoryByBrandName.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
      })
      .addCase(deleteCategory.pending, (state: ICategory) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state: ICategory, action) => {
        state.loading = false;
        state.refresh = true;
        state.success_message = "category deleted successfully";
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        state.refresh = true;
        state.error_message = action.payload as any;
      })
      .addCase(updateCategory.pending, (state: ICategory) => {
        state.loading = true;
      })
      .addCase(updateCategory.fulfilled, (state: ICategory, action) => {
        state.loading = false;
        state.refresh = true;
        state.success_message = "category updated successfully";
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        state.refresh = true;
        state.error_message = action.payload as any;
      })
      .addCase(createCategory.pending, (state: ICategory) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state: ICategory, action) => {
        state.loading = false;
        state.refresh = true;
        state.success_message = "category created successfully!";
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
        state.refresh = true;
      });
  },
});

export default categoryReducer.reducer;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";

const user = (state: RootState) => state.users_reducer;
const brand = (state: RootState) => state.brand_reducer;
const category = (state: RootState) => state.category_reducers;
export const usersData = createSelector(
  [user],
  (users) => users?.allUsersResponse
);
export const brandsData = createSelector(
  [brand],
  (brands) => brands.brand_data
);
export const categoriesData = createSelector(
  [category],
  (categories) => categories.category_data
);

import { useEffect } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import { getRoutes, router } from "./routes";
import { ForgotPassword, Login, ResetPassword, Signup } from "./pages";
import { protectedLayout } from "./routes/privateRoute";
import "./assets/styles/main.scss";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={protectedLayout}>
        {getRoutes(router)}
      </Route>
      <Route path="/auth" element={<Outlet />}>
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<Signup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default App;

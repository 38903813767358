import { ReactElement, FC, useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { columns } from "./utils/columns";
import { useMaterialUIController } from "../../context/mui.context";
import { sidenavEnums } from "../../context/types";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getAllBrandSelector } from "./selectors";
import { deleteBrand, getAllBrands } from "../../redux/actions/brand_services";
import { BrandType } from "../../types/brand.types";
import { clearMessage } from "../../redux/reducers/brand_reducer";
import AddEditBrand from "./components/addEditBrand";
import AntTable from "../../ant_components/Table";
import ColumnsBuilder from "../../ant_components/Table/lib/columnBuilder";
import styles from "./style.module.scss";

const Brand: FC<any> = (): ReactElement => {
  const [selectedBrand, setSelectedBrand] = useState<BrandType>();
  const appDispatch = useAppDispatch();
  const brands = useAppSelector(getAllBrandSelector);
  const { refresh, loading } = useAppSelector(
    (state: RootState) => state?.brand_reducer
  );
  const {
    muiState: { openDrawer, drawerTitle },
    dispatch,
  } = useMaterialUIController();

  useEffect(() => {
    appDispatch(getAllBrands());
  }, [appDispatch, refresh]);

  useEffect(() => {
    appDispatch(clearMessage({}));
  }, [appDispatch]);

  const columnsBuilder = new ColumnsBuilder()
    .basedOn(columns)
    .merge("action", {
      align: "center",
      render: (text, record) => (
        <Row
          gutter={16}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setSelectedBrand(record);
              dispatch({
                type: sidenavEnums?.DRAWER_TITLE,
                payload: "Edit Brand",
              });
              dispatch({
                type: sidenavEnums?.OPEN_DRAWER,
                payload: true,
              });
            }}
          >
            <EditOutlined />
          </Button>
          <Button onClick={() => appDispatch(deleteBrand(record?._id))}>
            <DeleteOutlined />
          </Button>
        </Row>
      ),
    })
    .build();

  return (
    <Row className={styles.container}>
      <Col span={24} className={styles.add_brand}>
        <Button
          title="Add Brand"
          children="Add Brand"
          onClick={() => {
            dispatch({
              type: sidenavEnums?.OPEN_DRAWER,
              payload: true,
            });
            dispatch({
              type: sidenavEnums?.DRAWER_TITLE,
              payload: "Add Brand",
            });
          }}
        />
      </Col>
      <Col span={24}>
        <AntTable columns={columnsBuilder} data={brands} loading={loading} />
      </Col>
      {openDrawer && drawerTitle !== "" && (
        <AddEditBrand selectedBrand={selectedBrand} />
      )}
    </Row>
  );
};

export default Brand;

import { FC, ReactElement, ReactNode } from "react";
import { Button, Drawer, Space } from "antd";
import { useMaterialUIController } from "../../context/mui.context";
import { sidenavEnums } from "../../context/types";

interface IProps {
  children: ReactNode;
}

const AntDrawer: FC<IProps> = ({ children }): ReactElement => {
  const {
    muiState: { openDrawer, drawerTitle },
    dispatch,
  } = useMaterialUIController();

  const handleCloseDrawer = () => {
    dispatch({ type: sidenavEnums?.OPEN_DRAWER, payload: false });
    dispatch({ type: sidenavEnums?.DRAWER_TITLE, payload: "" });
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      width={600}
      closeIcon={true}
      onClose={handleCloseDrawer}
      open={openDrawer}
      children={children}
    />
  );
};

export default AntDrawer;

import { createSelector } from "reselect";
import { RootState } from "../../../redux/store";

const user = (state: RootState) => state.users_reducer;
const auth = (state: RootState) => state.auth_reducer;
export const getAllUsersSelector = createSelector(
  [user],
  (data) => data?.allUsersResponse
);
export const getAllTypeOfUserSelector = createSelector(
  [user],
  (data) => data?.allTypesOfuserResponse
);
export const userInfo = createSelector([user], (data) => data?.userDetail);
export const userData = createSelector([auth], (data) => data?.signUpResponse);
export const loginData = createSelector([auth], (data) => data.loginResponse);
export const resetPasswordData = createSelector([auth], (data) => data.resetPasswordResponse);
export const accessToken = createSelector(
  [auth],
  (data) => data?.loginResponse?.tokens?.accessToken
);

import { ColumnsType } from 'antd/lib/table';
import { ColumnType } from 'antd/lib/table/interface';

interface MergeConfig<T> {
  columnKey: keyof T | 'action';
  newColumnProps: ColumnType<T>;
}

export class ColumnsBuilder<T extends object = any> {
  private columns: ColumnsType<T> = [];
  private columnsToMerge: MergeConfig<T>[] = [];

  basedOn(columns: ColumnsType<T>) {
    this.columns = columns;
    return this;
  }

  merge(columnKey: keyof T | 'action', newColumnProps: ColumnType<T>) {
    this.columnsToMerge.push({
      columnKey,
      newColumnProps,
    });
    return this;
  }

  build() {
    this.columns = this.columns.map((column) => {
      const mergedConfig: ColumnType<T> = this.columnsToMerge
        .filter((mergeColumn) => mergeColumn.columnKey === column.key)
        .reduce(
          (pre, next) => ({
            ...pre,
            ...next.newColumnProps,
          }),
          {}
        );
      return {
        ...column,
        ...mergedConfig,
      };
    });
    return this.columns;
  }
}

export default ColumnsBuilder;

import { FC, ReactElement, memo, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Space, Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/es/upload";
import { useMaterialUIController } from "../../../context/mui.context";
import { AntDrawer } from "../../../ant_components";
import { useAppDispatch } from "../../../redux/store";
import {
  createBrand,
  updateBrand,
} from "../../../redux/actions/brand_services";
import { sidenavEnums } from "../../../context/types";
import { BASE_URL } from "../../../config/axios.config";
import { IbrandState } from "../utils/constants";
import { FILE_UPLOAD_DONE } from "../../../utils";
import { BrandType } from "../../../types/brand.types";
import _ from "lodash";

interface IProps {
  selectedBrand?: BrandType;
}

const AddEditBrand: FC<IProps> = ({ selectedBrand }): ReactElement => {
  const [form] = Form.useForm();
  const appDispatch = useAppDispatch();
  const {
    dispatch,
    muiState: { drawerTitle },
  } = useMaterialUIController();

  useEffect(() => {
    if (drawerTitle === "Edit Brand") {
      form.setFieldsValue(selectedBrand);
    }
  }, [drawerTitle,selectedBrand,form]);

  const handlesubmit = () => {
    const payload = {
      name: _.trim(form.getFieldValue("name")),
      brand_img: form.getFieldValue("brand_img"),
    };
    if (drawerTitle === "Add Brand") {
      appDispatch(createBrand(payload));
      form.resetFields();
      dispatch({
        type: sidenavEnums?.OPEN_DRAWER,
        payload: false,
      });
    }
    if (drawerTitle === "Edit Brand") {
      const editPayload = {
        ...payload,
        _id: selectedBrand?._id,
      };
      appDispatch(updateBrand(editPayload));
      form.resetFields();
      dispatch({
        type: sidenavEnums?.OPEN_DRAWER,
        payload: false,
      });
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    listType: "picture",
    type: "select",
    action: `${BASE_URL}/upload/file`,
    method: "PATCH",
    maxCount: 1,
    onChange(info: UploadChangeParam) {
      if (info.file.status === FILE_UPLOAD_DONE) {
        message.success(`file ${info.file.name} upload success`);
        if (info?.file?.response?.url?.includes("pdf")) {
          form.setFieldValue("brand_pdf", info?.file?.response?.url);
        } else {
          form.setFieldValue("brand_img", info?.file?.response?.url);
        }
      }
    },
  };

  return (
    <AntDrawer>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={IbrandState}
      >
        <Form.Item name="name" label="Brand Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="brand_img"
          label="Brand Image"
          rules={[{ required: true }]}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button htmlType="submit" onClick={handlesubmit}>
              Submit
            </Button>
            <Button htmlType="reset">Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </AntDrawer>
  );
};

export default memo(AddEditBrand);

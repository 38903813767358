import _ from "lodash";
import { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { Col, Layout, Row, theme, FloatButton } from "antd";
import { useLocation } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import { router } from "../../routes";
import { routes } from "../../types/router.types";
import AntHeader from "../Header";
import AntSider from "../Sider";
import styles from "./style.module.scss";

interface IProps {
  children: ReactNode;
}

const { Content } = Layout;

const AntLayout: FC<IProps> = ({ children }): ReactElement => {
  var [date, setDate] = useState(new Date());
  const { pathname } = useLocation();
  const {
    token: { colorBgBase, borderRadiusLG, colorBgLayout },
  } = theme.useToken();

  const Title: string = _.toString(
    _.find(router, (e: routes) => e?.key === pathname)?.label
  );

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <Layout className={styles.container}>
      <AntHeader />
      <Layout>
        <AntSider />
        <Layout style={{ padding: "15px", background: colorBgLayout }}>
          <Row justify="space-between" className={styles.subheader}>
            <Col span={12}>{Title}</Col>
            <Col span={12}>
              <CalendarOutlined style={{ paddingRight: "10px" }} />
              {date.toLocaleString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })}
            </Col>
          </Row>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgBase,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </Layout>
        <FloatButton.BackTop />
      </Layout>
    </Layout>
  );
};

export default AntLayout;

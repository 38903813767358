import _ from "lodash";
import { IMuiContextType, MuiContextAction, sidenavEnums } from "./types";

export const muiContextReducer = (
  state: IMuiContextType,
  action: MuiContextAction
): IMuiContextType => {
  switch (action.type) {
    case sidenavEnums.DARKMODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case sidenavEnums.MINI_SIDENAV:
      return {
        ...state,
        miniSidenav: action.payload,
      };
    case sidenavEnums.WHITE_SIDENAV:
      return {
        ...state,
        whiteSidenav: action.payload,
      };
    case sidenavEnums.TRANSPARENT_SIDENAV:
      return {
        ...state,
        transparentSidenav: action.payload,
      };
    case sidenavEnums.SIDENAV_COLOR:
      return {
        ...state,
        sidenavColor: _.toString(action.payload),
      };
    case sidenavEnums.DRAWER_TITLE:
      return {
        ...state,
        drawerTitle: _.toString(action.payload),
      };
    case sidenavEnums.OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.payload,
      };
    case sidenavEnums.FULLSCREEN:
      return {
        ...state,
        openFullScreen: action.payload,
      };
    default:
      return state;
  }
};

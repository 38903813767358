import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getuser,
  getuserTypes,
  getusers,
} from "../actions/user_service";
import {
  allUsersType,
  getTypesOfuserType,
  UserModel,
} from "../../types/user.types";

interface IUser {
  refresh: boolean;
  success_message: string;
  error_message: string;
  loading: boolean;
  refresh_page: boolean;
  allUsersResponse: allUsersType;
  allTypesOfuserResponse: getTypesOfuserType;
  userDetail: UserModel;
}

const Istate: IUser = {
  loading: false,
  refresh: false,
  error_message: "",
  success_message: "",
  refresh_page: false,
  allUsersResponse: [],
  allTypesOfuserResponse: [],
  userDetail: {
    email: "",
    password: "",
    _id: "",
    name: "",
    phoneNumber: "",
    typeOfuser: "",
  },
};

const authReducer = createSlice({
  name: "auth",
  initialState: Istate,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getuserTypes.pending, (state: IUser) => {
        state.loading = true;
      })
      .addCase(getuserTypes.fulfilled, (state: IUser, action) => {
        state.loading = false;
        state.allTypesOfuserResponse = action.payload.data;
      })
      .addCase(getuserTypes.rejected, (state, action) => {
        state.loading = false;
        state.error_message = action.payload as any;
      })
      .addCase(getuser.pending, (state: IUser) => {
        state.loading = true;
      })
      .addCase(getuser.fulfilled, (state: IUser, action) => {
        state.loading = false;
        state.userDetail = action.payload.data;
      })
      .addCase(getuser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUser.pending, (state: IUser) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state: IUser, action) => {
        state.loading = false;
        state.refresh = true;
        state.userDetail = action.payload.data;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getusers.pending, (state: IUser) => {
        state.loading = true;
      })
      .addCase(getusers.fulfilled, (state: IUser, action) => {
        state.loading = false;
        state.refresh = false;
        state.allUsersResponse = action.payload?.data;
      })
      .addCase(getusers.rejected, (state, action) => {
        state.loading = false;
        state.refresh = false;
        state.error_message = action.payload as any;
      });
  },
});

export default authReducer.reducer;

import _ from "lodash";
import { FC, useEffect } from "react";
import { Button, Flex, Form, Grid, Input, Spin, theme, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { reset_password } from "../../redux/actions/auth_servces";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { resetPasswordData } from "./selectros";
import Logo from "../../assets/imges/Transparent.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const ResetPassword: FC<any> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(resetPasswordData);
  const screens = useBreakpoint();
  const { token } = useToken();
  const [form] = Form.useForm();
  const { loading } = useAppSelector((state: RootState) => state.auth_reducer);

  const onFinish = (values: any) => {
    const payload = {
      newPassword: values.newPassword,
      resetToken: params.token,
    };
    dispatch(reset_password(payload));
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      navigate("/auth/login");
    }
  }, [user]);

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <section style={styles.section}>
      {loading ? (
        <Flex
          align="center"
          gap="middle"
          justify="center"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Spin size="large" />
        </Flex>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <img
              src={Logo}
              alt="logo"
              style={{ height: "170px", width: "100%" }}
            />
            <Title style={styles.title}>Reset Password</Title>
            <Text style={styles.text}>
              Welcome back to ListDekhoo Please enter your details below to sign
              in.
            </Text>
          </div>
          <Form
            form={form}
            name="normal_login"
            initialValues={{
              newPassword: "",
            }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="new Password"
              />
            </Form.Item>
            <Form.Item>
              <Link onClick={() => navigate("/auth/forgot-password")}>
                Forgot password
              </Link>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" htmlType="submit">
                Reset
              </Button>
              <div>
                <Text>Don't have an account?</Text>
                <Link onClick={() => navigate("/auth/sign-up")}>
                  Sign up now
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </section>
  );
};

export default ResetPassword;

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { MaterialUIContextProvider } from "./context/mui.context";
import { store } from "./redux/store";
import theme from "./ant_components/theme";
import App from "./App";

const element: HTMLElement = document.getElementById("root") as HTMLElement;
const root = createRoot(element);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MaterialUIContextProvider>
        <ConfigProvider theme={theme}>
          <App />
        </ConfigProvider>
      </MaterialUIContextProvider>
    </Provider>
  </BrowserRouter>
);

import { createSlice } from "@reduxjs/toolkit";
import {
  forgot_password,
  login,
  reset_password,
  signup,
} from "../actions/auth_servces";
import { ISignInResponse, ISignupResponse } from "../../types/user.types";

interface IAuth {
  loading: boolean;
  refresh_page: boolean;
  signUpResponse: ISignupResponse;
  loginResponse: ISignInResponse;
  forgotPasswordResponse: any;
  resetPasswordResponse: any;
}

const Istate: IAuth = {
  loading: false,
  refresh_page: false,
  forgotPasswordResponse: "",
  resetPasswordResponse: "",
  signUpResponse: {
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    typeOfuser: "",
  },
  loginResponse: {
    tokens: {
      accessToken: "",
      refreshToken: "",
    },
    userId: "",
  },
};

const authReducer = createSlice({
  name: "auth",
  initialState: Istate,
  reducers: {
    clearToken(state) {
      state.loginResponse.tokens = {
        accessToken: "",
        refreshToken: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state: IAuth) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state: IAuth, action) => {
        state.loading = false;
        state.signUpResponse = action.payload.data;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(login.pending, (state: IAuth) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state: IAuth, action) => {
        state.loading = false;
        state.loginResponse = action.payload?.data;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(forgot_password.pending, (state: IAuth) => {
        state.loading = true;
      })
      .addCase(forgot_password.fulfilled, (state: IAuth, action) => {
        state.loading = false;
        state.forgotPasswordResponse = action.payload?.data;
      })
      .addCase(forgot_password.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(reset_password.pending, (state: IAuth) => {
        state.loading = true;
      })
      .addCase(reset_password.fulfilled, (state: IAuth, action) => {
        state.loading = false;
        state.resetPasswordResponse = action.payload?.data;
      })
      .addCase(reset_password.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { clearToken } = authReducer.actions;

export default authReducer.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { createCategoryPayload } from "../../types/category.types";
import axiosInstance, { category_ext } from "../../config/axios.config";
import _ from "lodash";

export const getAllCategory = createAsyncThunk(
  "get_all_category",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `${category_ext}/getAll`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getCategoryByBrandName = createAsyncThunk(
  "get_category",
  async (brand_name: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `${category_ext}/?brand_name=${brand_name}`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "createCategory",
  async (data: createCategoryPayload, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${category_ext}/create`,
        data
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateBrand",
  async (data: any, thunkAPI) => {
    const id = data?._id;
    const payload = _.omit(data, "_id");
    try {
      const response: AxiosResponse = await axiosInstance.put(
        `${category_ext}/update/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (id: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${category_ext}/delete/${id}`
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

function LineChart(data: any) {
  const lineChart: ApexOptions | any = {
    series: [
      {
        name: "users",
        data: [350, 40, 300, 220, 500, 250, 400, 230, 500],
        offsetY: 0,
      },
      {
        name: "categories",
        data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
        offsetY: 0,
      },
      {
        name: "brands",
        data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
        offsetY: 0,
      },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
        },
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },

      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  };
  return (
    <>
      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={280}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;

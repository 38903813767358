import { theme, ThemeConfig } from "antd";
import { cyan, green } from "@ant-design/colors";

const primaryColor: string = green[6];
const headerTextColor: string = "#ffffff";

const antdTheme: ThemeConfig = {
  token: {
    borderRadius: 2,
    colorPrimary: primaryColor,
    colorBgContainer: "#fff",
    borderRadiusLG: 5,
    colorBgBase: "#fff",
    fontSize: 16,
    colorBgLayout: "#ffffb8",
  },
  components: {
    Layout: {
      algorithm: true,
      headerColor: headerTextColor,
      headerBg: "#fff",
      headerHeight: "70px",
      headerPadding: "0px",
      siderBg: "#52c41a",
      fontSize: 16,
    },
    Button: {
      colorBgContainer: green[5],
      colorBorder: cyan[4],
      borderRadius: 10,
      paddingBlock: 15,
      fontSize: 14,
      fontWeight: "bold",
      fontFamily: "nunito",
      colorText: "#fff",
      defaultShadow: "0px 0px 9px 0px orange",
    },
    Drawer: {
      fontSizeHeading1: 20,
      boxShadow: "5px 5px red",
      colorBgLayout: "red",
    },
  },
  algorithm: [theme.compactAlgorithm],
  cssVar: true,
  hashed: false,
};

export default antdTheme;

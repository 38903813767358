import { ReactElement, useEffect, FC } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { allUsers } from "./selectros";
import { deleteUser, getusers } from "../../redux/actions/user_service";
import { sidenavEnums } from "../../context/types";
import { columns } from "./utils/columns";
import { ColumnsBuilder } from "../../ant_components/Table/lib/columnBuilder";
import { useMaterialUIController } from "../../context/mui.context";
import { AntTable } from "../../ant_components";

interface IProps {}

const User: FC<IProps> = (): ReactElement => {
  const appDispatch = useAppDispatch();
  const users = useAppSelector(allUsers);
  const { dispatch } = useMaterialUIController();
  const { loading, refresh } = useAppSelector(
    (state: RootState) => state?.users_reducer
  );
  useEffect(() => {
    appDispatch(getusers());
  }, [refresh,appDispatch]);

  const columnsBuilder = new ColumnsBuilder()
    .basedOn(columns)
    .merge("action", {
      align: "center",
      render: (text, record) => (
        <Row
          gutter={16}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              dispatch({
                type: sidenavEnums?.DRAWER_TITLE,
                payload: "Edit Brand",
              });
              dispatch({
                type: sidenavEnums?.OPEN_DRAWER,
                payload: true,
              });
            }}
          >
            <EditOutlined />
          </Button>
          <Button onClick={() => appDispatch(deleteUser(record?._id))}>
            <DeleteOutlined />
          </Button>
        </Row>
      ),
    })
    .build();

  return <AntTable columns={columnsBuilder} data={users} loading={loading} />;
};

export default User;

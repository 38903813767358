import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { loginPayload, signUpPayload } from "../../types/user.types";
import axiosInstance, { auth_ext } from "../../config/axios.config";

export const signup = createAsyncThunk(
  "signup",
  async (data: signUpPayload, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${auth_ext}/signup`,
        data
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      const message: any = err.response?.data;
      return thunkAPI.rejectWithValue(message?.errorResponse?.message);
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async (data: loginPayload, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${auth_ext}/login`,
        data
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const forgot_password = createAsyncThunk(
  "forgot_password",
  async (email: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${auth_ext}/forgotPassword`,
        { email }
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const reset_password = createAsyncThunk(
  "reset_password",
  async (data: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${auth_ext}/resetPassword`,
        data
      );
      return response.data;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

import { FC, ReactElement, memo, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Space, Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { UploadProps } from "antd/es/upload/interface";
import { useMaterialUIController } from "../../../context/mui.context";
import { AntDrawer } from "../../../ant_components";
import { useAppDispatch } from "../../../redux/store";
import { getAllBrands } from "../../../redux/actions/brand_services";
import { sidenavEnums } from "../../../context/types";
import { BASE_URL } from "../../../config/axios.config";
import { FILE_UPLOAD_DONE } from "../../../utils";
import { BrandType } from "../../../types/brand.types";
import { ICategoyrState } from "../utils/constant";
import { createCategoryPayload } from "../../../types/category.types";
import {
  createCategory,
  updateCategory,
} from "../../../redux/actions/category_services";

interface IProps {
  brandDetails: BrandType;
  selectedCategory?: any;
}

const AddEditCategory: FC<IProps> = ({
  brandDetails,
  selectedCategory,
}): ReactElement => {
  const [form] = Form.useForm();
  const appDispatch = useAppDispatch();
  const {
    dispatch,
    muiState: { drawerTitle },
  } = useMaterialUIController();

  useEffect(() => {
    form.setFieldsValue(selectedCategory);
  }, [selectedCategory,form]);

  useEffect(() => {
    appDispatch(getAllBrands());
  }, [appDispatch]);

  const handlesubmit = () => {
    const payload: createCategoryPayload = {
      name: form.getFieldValue("name"),
      category_img: form.getFieldValue("category_img"),
      category_pdf: form.getFieldValue("category_pdf"),
      brand: brandDetails,
    };
    if (drawerTitle === "Add Category") {
      appDispatch(createCategory(payload));
      form.resetFields();
      dispatch({
        type: sidenavEnums?.OPEN_DRAWER,
        payload: false,
      });
    }
    if (drawerTitle === "Edit Category") {
      const editPayload = {
        ...payload,
        _id: selectedCategory?._id,
      };
      appDispatch(updateCategory(editPayload));
      form.resetFields();
      dispatch({
        type: sidenavEnums?.OPEN_DRAWER,
        payload: false,
      });
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    listType: "picture",
    type: "select",
    action: `${BASE_URL}/upload/file`,
    method: "PATCH",
    maxCount: 1,
    onChange(info: UploadChangeParam) {
      if (info.file.status === FILE_UPLOAD_DONE) {
        message.success(`file ${info.file.name} upload success`);
        if (info?.file?.response?.url?.includes("pdf")) {
          form.setFieldValue("category_pdf", info?.file?.response?.url);
        } else {
          form.setFieldValue("category_img", info?.file?.response?.url);
        }
      }
    },
  };

  return (
    <AntDrawer>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={ICategoyrState}
      >
        <Form.Item
          name="name"
          label="Category Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category_img"
          label="Category Image"
          rules={[{ required: true }]}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="category_pdf"
          label="Category Pdf"
          rules={[{ required: true }]}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button htmlType="submit" onClick={handlesubmit}>
              Submit
            </Button>
            <Button htmlType="reset">Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </AntDrawer>
  );
};

export default memo(AddEditCategory);

import {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  FC,
  ReactElement,
  Dispatch,
} from "react";
import { IMuiContextType, MuiContextAction, muiContextIState } from "./types";
import { muiContextReducer } from "./reducers";

type ContextProviderProps = {
  children: ReactNode;
};

const MaterialUIContext = createContext<{
  muiState: IMuiContextType;
  dispatch: Dispatch<MuiContextAction>;
}>({ muiState: muiContextIState, dispatch: () => null });

export const MaterialUIContextProvider: FC<ContextProviderProps> = ({
  children,
}): ReactElement => {
  const [muiState, dispatch] = useReducer(muiContextReducer, muiContextIState);
  return (
    <MaterialUIContext.Provider value={{ muiState, dispatch }}>
      {children}
    </MaterialUIContext.Provider>
  );
};

export function useMaterialUIController() {
  const context = useContext(MaterialUIContext);
  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }
  return context;
}

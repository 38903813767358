export type SIDENAV_TYPES =
  | "DARKMODE"
  | "LAYOUT"
  | "DIRECTION"
  | "OPEN_CONFIGURATOR"
  | "FIXED_NAVBAR"
  | "MINI_SIDENAV"
  | "TRANSPARENT_SIDENAV"
  | "WHITE_SIDENAV"
  | "SIDENAV_COLOR"
  | "TRANSPARENT_NAVBAR"
  | "OPEN_DRAWER"
  | "FULLSCREEN"
  | "DRAWER_TITLE";

export enum sidenavEnums {
  OPEN_DRAWER = "OPEN_DRAWER",
  DARKMODE = "DARKMODE",
  LAYOUT = "LAYOUT",
  DIRECTION = "DIRECTION",
  OPEN_CONFIGURATOR = "OPEN_CONFIGURATOR",
  FIXED_NAVBAR = "FIXED_NAVBAR",
  MINI_SIDENAV = "MINI_SIDENAV",
  TRANSPARENT_SIDENAV = "TRANSPARENT_SIDENAV",
  WHITE_SIDENAV = "WHITE_SIDENAV",
  SIDENAV_COLOR = "SIDENAV_COLOR",
  TRANSPARENT_NAVBAR = "TRANSPARENT_NAVBAR",
  DRAWER_TITLE = "DRAWER_TITLE",
  FULLSCREEN = "FULLSCREEN",
}
export type IMuiContextType = {
  drawerTitle: string;
  displayName: string;
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: string;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: "ltr" | "rtl";
  layout: string;
  darkMode: boolean;
  openDrawer: boolean;
  openFullScreen: boolean;
};

export type MuiContextAction =
  | { type: sidenavEnums.DARKMODE; payload: boolean }
  | { type: sidenavEnums.FIXED_NAVBAR; payload: boolean }
  | { type: sidenavEnums.TRANSPARENT_NAVBAR; payload: boolean }
  | { type: sidenavEnums.TRANSPARENT_SIDENAV; payload: boolean }
  | { type: sidenavEnums.WHITE_SIDENAV; payload: boolean }
  | { type: sidenavEnums.DRAWER_TITLE; payload: string }
  | { type: sidenavEnums.OPEN_CONFIGURATOR; payload: boolean }
  | { type: sidenavEnums.SIDENAV_COLOR; payload: string }
  | { type: sidenavEnums.LAYOUT; payload: string }
  | { type: sidenavEnums.DIRECTION; payload: string }
  | { type: sidenavEnums.MINI_SIDENAV; payload: boolean }
  | { type: sidenavEnums.FULLSCREEN; payload: boolean }
  | { type: sidenavEnums.OPEN_DRAWER; payload: boolean };

export const muiContextIState: IMuiContextType = {
  displayName: "MuiContext",
  drawerTitle: "",
  darkMode: false,
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  fixedNavbar: true,
  openConfigurator: false,
  direction: "ltr",
  layout: "dashboard",
  transparentNavbar: true,
  openDrawer: false,
  openFullScreen: false,
};

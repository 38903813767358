import { createCategoryPayload } from "../../../types/category.types";

export const ICategoyrState: createCategoryPayload = {
  name: "",
  category_img: "",
  category_pdf: "",
  brand: {
    name: "",
    _id: "",
  },
};
